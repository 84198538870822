export const isUrlExists = () => {
  return !!localStorage.returnUrl;
};

export const setUrl = (returnUrl) => {
  localStorage.setItem("returnUrl", returnUrl);
};

export const getUrl = (id) => {
  const url = `${localStorage.getItem("returnUrl")}?id=${id}`;
  return url;
};

export const removeUrl = () => {
  localStorage.removeItem("returnUrl");
};
