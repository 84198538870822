<template>
  <div></div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { setUrl, removeUrl } from "@/core/utils/returnUrlController";

export default {
  setup() {
    removeUrl();
    const route = useRoute();
    const router = useRouter();
    const returnUrl = route.query.return;
    if (!returnUrl) return router.push({ name: "login" });
    setUrl(returnUrl);
    router.push({ name: "login" });
  },
};
</script>

<style lang="scss" scoped src="./External.scss"></style>
